<template>
  <div style="width:100%;height:100vh;background-color: #000710">
  <Loading v-if="showLoading"/>
  <div id="box">
    <div class="body">
      <div class="head" v-if="infoData">
        <div class="logo"></div>
        <div class="live_name">
          <div class="live_message">
            <a-avatar :src="JSON.parse(infoData.author).avatar" :size="44" />
            <div class="comment_content">
              <p>{{JSON.parse(infoData.author).nickname }}</p>
              <div class="content">{{infoData.title}}</div>
            </div>
          </div>
        </div>
        <div class="watch_link">
        </div>
        <div class="tips_left">
          <div>直播开始时间</div>
          <div>{{infoData.startTime ? infoData.startTime.slice(0, 16) : "" }}</div>
        </div>
        <div class="tips_right">
          <div>
            <a-icon type="bar-chart" style="color:#CB6162"></a-icon>直播中
          </div>
          <div>直播时长：{{diffTimeText}}</div>
        </div>
      </div>
      <div class="content">
        <!-- 左侧内容 -->
        <div class="box_left">
          <div class="chart" v-for="(item,index) in lineChartList" :key="index">
            <div class="chart-title"><div class="img_box"><img src="./img/arrows.png" alt /></div>{{item.name}}</div>
            <aside>
              <span style="color:#61C2C6;font-size:10px">增量</span>
              <span style="color:#2A90F4;font-size:10px">总量</span>
            </aside>
            <LiveLine
              :id="item.id"
              :box-style="{width:'100',height:'80'}"
              :chart-data="item.chartDate"
              :ref="item.id"
            />
          </div>
        </div>
        <!-- 中心内容 -->
        <div class="center_box">
          <div class="fans">
            <ul class="data_box">
              <li v-for="(item,index) in fansList" :key="index">
                <span>{{item.name}}</span>
                <span>{{item.value || 0}}</span>
              </li>
            </ul>
          </div>
          <div class="live">
            <div class="live-box">
              <header class="topCar">
                <div class="img_box"><img src="./img/arrows.png" alt /></div>观众关注车型 TOP5
              </header>
              <div class="live-box-content">
                <ul class="hot_car" v-if="hotCarList.length>0">
                  <li v-for="(item,index) in hotCarList" :key="index" >
                    <div class="content">
                      <div class="img_box">
                        <img :src="item.image" alt />
                      </div>
                      <p>{{item.series}}</p>
                    </div>
                    <div class="progress">
                      <div class="progress_box">
                        <div class="process_rate" :style="{'background-image':index>0?(index>2?'linear-gradient(270deg, #005B8D 0%, rgba(0,89,136,0.00) 100%)':'linear-gradient(270deg, #007E8F 0%, rgba(0,126,139,0.00) 100%)'):'linear-gradient(270deg, #66131F 0%, rgba(227,73,84,0.00) 100%)',width:item.rate+'%'}"></div>
                        <img src="./img/rateA.png" v-if="index==0"/>
                        <img src="./img/rateB.png" v-if="index>=1&&index<=2"/>
                        <img src="./img/rateC.png" v-if="index>2"/>
                      </div>
                      <div class="outside_tips">{{item.count}}</div>
                    </div>
                  </li>
                </ul>
                <div v-else style="padding-top:100px;text-align:center;color:#ccc">暂无数据</div>
              </div>
            </div>
            <!-- 实时直播 -->
            <div class="video_box">
              <video
                  id="videoElement"
                  autoplay
                  muted
                  style="
                    width: calc(100% - 2px);
                    height: calc(100% - 12px);
                    border-radius: 10px;
                  "
                  v-if="this.common.isDemo()"
                ></video>
                <video
                  id="live-video"
                  class="video-js vjs-default-skin"
                  style="
                    width: calc(100% - 2px);
                    height: calc(100% - 12px);
                    border-radius: 10px;
                  "
                  autoplay
                  muted
                  controls
                  ref="liveVideo"
                  preload="auto"
                  v-else
                >
                  <source :src="videoUrl" type="application/x-mpegURL" />
                </video>
            </div>
            <div class="live-box">
              <header class="comment_header">
                <div class="img_box"><img src="./img/arrows.png" alt /></div>评论
              </header>
              <div class="live-box-content">
                <ul class="comment_lists">
                  <transition-group name="list-complete" tag="p">
                    <li v-for="item in commentList" :key="item.id" class="list-complete-item">
                      <a-avatar :src="item.avatar" />
                      <div class="comment_content">
                        <p>{{item.name}}</p>
                        <div class="comment">{{item.content}}</div>
                      </div>
                    </li>
                  </transition-group>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- 右侧内容 -->
        <div class="box_right">
          <div class="chart">
            <div class="chart-title"><div class="img_box"><img src="./img/arrows.png" alt /></div>性别分布</div>
            <div v-if="!genderList" style="color:#999;text-align:center;padding-top:40px">暂无数据</div>
            <PieChart
              :id="'fansGenderChart'"
              :box-style="{width:'100',height:'90'}"
              :pie-data="genderList"
              v-else
            />
          </div>
          <div class="chart">
            <div class="chart-title"><div class="img_box"><img src="./img/arrows.png" alt /></div>地域分布</div>
            <MapChart
              :id="'MapChart'"
              :box-style="{width:'100',height:'90'}"
              :chart-data="mapChartList"
            />
          </div>
          <div class="chart">
            <div class="chart-title"><div class="img_box"><img src="./img/arrows.png" alt /></div>城市分布</div>
            <table class="city_table_rate">
              <tr>
                <th>城市</th>
                <th>占比</th>
              </tr>
              <!-- <tbody> -->
              <tr v-for="(item,index) in mapChartList" :key="index" class="tr_overflow">
                <td>{{item.province}}</td>
                <td>{{item.count?(item.count*100).toFixed(2):''}}%</td>
              </tr>
              <!-- </tbody> -->
            </table>
          </div>
          <div class="chart">
            <div class="chart-title"><div class="img_box"><img src="./img/arrows.png" alt /></div>评论词云</div>
            <WordCould
              :id="'WordCouldNOW'"
              :box-style="{width:'100',height:'90'}"
              :chart-data="hotWordList"
            />
          </div>
        </div>
      </div>
      <div class="foot"></div>
    </div>
  </div>
  </div>

</template>
<script>
// import Cookies from "js-cookie";
import LiveLine from "./charts/LiveLine";
import PieChart from "./charts/PieChart";
import WordCould from "./charts/WordCould";
import MapChart from "./charts/MapChart";
import Paho from "@/mirror-modules/mqttws31.min-bac.js";
import videojs from "video.js";
// import 'video.js/dist/video-js.css'
import "videojs-contrib-hls";
import "flv.js/dist/flv.min.js";
import flvjs from "flv.js";
import Loading from "./Loading";
import { createNamespacedHelpers } from "vuex";
import moment from 'moment'
// import Progress from '@/components/Item/Progress'
import MqttRemoval from '@/mirror-modules/mqttRemoval'
// import settings from '../../store/modules/settings';
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(
  "live_now"
);
import Hls from "hls.js";

export default {
  components: {
    LiveLine,
    WordCould,
    PieChart,
    MapChart,
    Loading,
    // Progress
  },
  props: {
    roomId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      commentList: [],//
      fansList: [
        {
          name: "累计观看人数",
          value: 0,
        },
        {
          name: "粉丝变化",
          value: 0,
        },
        {
          name: "观看人数峰值",
          value: 0,
        },
        {
          name: "直播前粉丝数",
          value: 0,
        },
      ],//
      lineChartList: [
        {
          name: "观看人数趋势",
          chartDate: {
            name: ["增量", "总量"],
            xAxis: [],
            yAxis: [[], []],
          },
          id: "lineA",
        },
        {
          name: "粉丝变化趋势",
          chartDate: {
            name: ["增量", "总量"],
            xAxis: [],
            yAxis: [[], []],
          },
          id: "lineB",
        },
        {
          name: "评论人数趋势",
          chartDate: {
            name: ["增量", "总量"],
            xAxis: [],
            yAxis: [[], []],
          },
          id: "lineC",
        },
        {
          name: "送礼数趋势",
          chartDate: {
            name: ["增量", "总量"],
            xAxis: [],
            yAxis: [[], []],
          },
          id: "lineD",
        },
      ], //
      hotCarList: [], // 车型top5 //
      mapChartList: [], // 地域分布 //
      genderList: null, // 性别分布 // 
      hotWordList: [], // 评论词云 // 
      userId: (window.localStorage.getItem('token_val') || '').slice(0, 10),//
      client: null, // 创建一个客户端实例 //
      showLoading:true, //
      timer:null, //
      screenWidth:1920,//
      timerWl:null, //
      removal: null, //
      videoUrl: '' //
    };
  },
  computed: {
    ...mapState({
      mqttConfigData: state => state.mqttConfigData, // mqtt
      infoData: state => state.infoData,
    })
  },
  created() {

    // 清除loading
    let clear = setTimeout(() => {
      this.showLoading = false;
      clearTimeout(clear);
    }, 1500);
    // platformType :快手2，抖音：1
    this.changeState({ name: "room_id", data: this.$route.params.roomId });
    // TODO
    this.getMqttConfig({
      platformType: 1,
      authorId: this.$route.query.author_id,
      deviceId: this.userId,
    });
    this.getInfo({
      room_id: this.$route.params.roomId,
    });
    this.addLiveRoom({
      roomId: this.$route.params.roomId,
    });
  },
  watch: {
    screenWidth(val){
        // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
        if(!this.timerWl){
            // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
            this.screenWidth = val
            this.timerWl = true
            let that = this
            setTimeout(function(){
                document.getElementById('box').style.transform = `scale(${that.screenWidth/1920})`
                that.timerWl = false
            },400)
        }
    },
    mqttConfigData() {
      this.removal = new MqttRemoval();
      this.MQTTconnect();
    },
    infoData() {
      console.log(this.infoData);
      if (this.infoData) {
        this.fansList[3].value = JSON.parse(this.infoData.author).fans_count;

        this.diffTimeText = this.diffTime(this.infoData.startTime);
        let timer = setInterval(() => {
          this.diffTimeText = this.diffTime(this.infoData.startTime);
        }, 1000 * 60);
        if (this.infoData.playUrl) {
          this.videoUrl = this.infoData.playUrl
          let that = this;
          console.log("视频链接", this.infoData.playUrl);
          this.player = videojs("live-video");
          this.player.src({
            src: that.infoData.playUrl.replace(/^http/, "https"),
          });
        }
      }
    },

  },
  mounted() {
    // 监听屏幕变化
    this.resizeWidth();
    window.addEventListener("resize", () => {
      this.resizeWidth();
    });
  },
  methods: {
    ...mapActions(["getMqttConfig", "getInfo", "addLiveRoom"]),
    ...mapMutations(["changeState"]),
    // 屏幕变化设置比
    resizeWidth(){
        this.screenWidth = document.body.clientWidth
    },
    format(percentage) {
      return this.common.numEdit(percentage);
    },
    diffTime(time) {
      let startTime = moment(time);
      let currentTime = moment();
      let diffTime = currentTime.diff(startTime, "minute"); //计算相差的分钟数
      let h = Math.floor(diffTime / 60); //相差的小时数
      let mm = diffTime % 60; //计算相差小时后余下的分钟数
      return `${h}小时${mm}分钟`;
    },
    // 创建mqtt实例，并连接
    MQTTconnect() {
      this.client = new Paho.MQTT.Client(
        this.mqttConfigData.endPoint,
        443,
        this.mqttConfigData.clientId
      );
      this.client.connect({
        userName: this.mqttConfigData.userName, //连接帐号
        password: this.mqttConfigData.password, //密码
        cleanSession: true,
        onSuccess: this.onConnect,
        useSSL: true,
        onFailure: message => {
          setTimeout(this.MQTTconnect, 2000);
        },
        timeout: 3,
        reconnect : true, 
        mqttVersion: 4
      }); // 连接服务器并注册连接成功处理事件
      this.client.onConnectionLost = this.onConnectionLost; // 注册连接断开处理事件
      this.client.onMessageArrived = this.onMessageArrived; // 注册消息接收处理事件
    },

    //当客户端连接
    onConnect() {
      //建立连接后，进行订阅并发送消息。
      console.log("onConnect");
      this.client.subscribe(
        this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic,
        { qos: 0 }
      );
    },

    // 当客户端失去连接时调用
    onConnectionLost(responseObject) {
      if (responseObject.errorCode !== 0) {
        console.log("onConnectionLost:" + responseObject.errorMessage);
      }
    },
    // require('./dev/defult-avatar.png')
    //当消息到达时
    // type 1:评论，4:礼物，5：进入直播间，6:关注
    // 抖音无法获取礼物数
    onMessageArrived(message) {
      let newJson = JSON.parse(message.payloadString);
      let bool = this.removal.isRemoval(newJson);
      console.log("onMessageArrived:", newJson);
      let obj = {};
      let commentData = "";
      const that = this;
      if (this.commentList.length > 1000) {
        this.commentList.splice(500, 499);
      }
      if (bool && newJson.type == 1) {
        commentData = newJson.data.content;
        this.commentList.unshift({
          id: `${parseInt(Math.random() * 10000)}${Date.parse(new Date())}`,
          name: newJson.data.userName,
          content: commentData,
          avatar: newJson.data.avatar
            ? newJson.data.avatar
            : require('./dev/defult-avatar.png'),
        });
      } else if (newJson.type == 4) {
        commentData = `送了${newJson.data.giftName}`;
        this.commentList.unshift({
          id: `${parseInt(Math.random() * 10000)}${Date.parse(new Date())}`,
          name: newJson.data.userName,
          content: commentData,
          avatar: newJson.data.avatar
            ? newJson.data.avatar
            : require('./dev/defult-avatar.png'),
        });
      } else if (newJson.type == 5) {
        commentData = "进入直播间";
        this.commentList.unshift({
          id: `${parseInt(Math.random() * 10000)}${Date.parse(new Date())}`,
          name: newJson.data.name,
          content: commentData,
          avatar: newJson.data.avatar
            ? newJson.data.avatar
            : require('./dev/defult-avatar.png'),
        });
      } else if (newJson.type == 6) {
        commentData = "点了关注";
        this.commentList.unshift({
          id: `${parseInt(Math.random() * 10000)}${Date.parse(new Date())}`,
          name: newJson.data.name,
          content: commentData,
          avatar: newJson.data.avatar
            ? newJson.data.avatar
            : require('./dev/defult-avatar.png'),
        });
      } else if (newJson.type == 2001) {
        this.hotCarList = this.setHotCar(newJson.data);
      } else if (newJson.type == 2002) {
        this.mapChartList = newJson.data;
      } else if (newJson.type == 2003) {
        this.hotWordList = newJson.data;
      } else if (newJson.type == 2004) {
        this.setGender(newJson.data);
      } else if (newJson.type == 2005) {
        this.lineChartList[0].chartDate.xAxis.push(newJson.data.time);
        this.lineChartList[0].chartDate.yAxis[0].push(
          newJson.data.user_count_increment
        );
        this.lineChartList[0].chartDate.yAxis[1].push(newJson.data.user_count);
        this.$refs.lineA[0].initChart();
      } else if (newJson.type == 2006) {
        this.lineChartList[1].chartDate.xAxis.push(newJson.data.time);
        this.lineChartList[1].chartDate.yAxis[0].push(
          newJson.data.follow_count_increment
        );
        this.lineChartList[1].chartDate.yAxis[1].push(
          newJson.data.follow_count
        );
        this.$refs.lineB[0].initChart();
      } else if (newJson.type == 2007) {
        this.lineChartList[2].chartDate.xAxis.push(newJson.data.time);
        this.lineChartList[2].chartDate.yAxis[0].push(
          newJson.data.comment_count_increment
        );
        this.lineChartList[2].chartDate.yAxis[1].push(
          newJson.data.comment_count
        );
        this.$refs.lineC[0].initChart();
      } else if (newJson.type == 2008) {
        this.lineChartList[3].chartDate.xAxis.push(newJson.data.time);
        this.lineChartList[3].chartDate.yAxis[0].push(
          newJson.data.gift_count_increment
        );
        this.lineChartList[3].chartDate.yAxis[1].push(newJson.data.gift_count);
        this.$refs.lineD[0].initChart();
      } else if (newJson.type == 2009) {
        this.fansList[0].value = newJson.data.accumulatedWatchingCount;
        this.fansList[1].value = newJson.data.fansChange;
        this.fansList[2].value = newJson.data.watchingPeakCount;
      }
    },
  },
  beforeDestroy() {
    this.client.unsubscribe(
      this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic
    );
    this.client.disconnect(); // 断开连接
    this.client = null;
    // 销毁video实例
    const videoDom = this.$refs.liveVideo;
    videojs(videoDom).dispose();
    this.commentList = [];
    window.removeEventListener(this.resizeWidth);
  },
};
</script>
<style lang="scss" scoped>
@mixin hideSCroll {
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
* {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
#box {
  width: 1920px;
  height: 1074px;
  transform-origin: left top;
  background-color: #000710;
}
.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}
.list-complete-enter
/* .list-complete-leave-active for below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(-50px);
}
//  .list-complete-leave-to
// /* .list-complete-leave-active for below version 2.1.8 */ {
//   opacity: 0;
//   transform: translateY(-30px);
// }
.list-complete-leave-active {
  position: absolute;
}
.body {
  width: 100%;
  height: 100%;
  background: url('./img/bgd.png');
  background-size:100% 100%;
  padding: 20px;
  .head {
    height: 11%;
    background: url("./img/topbgd.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    justify-content: space-around;
    .logo {
      flex: 1;
      margin: 10px 40px;
      width: 117px;
      height: 39px;
      //background: url("./img/logo.png") no-repeat;
      background-size: 100% 100%;
    }
    .live_name {
      // width: 1130px;
      flex: 8;
      .live_message {
        display: flex;
        // align-items: center;
        justify-content: center;
        color: #ffffff;
        padding-top: 10px;
        .comment_content {
          padding-left: 10px;
          p {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          .content {
            font-size: 14px;
            opacity: 0.4;
            margin: 5px 0;
          }
        }
      }
    }
    .watch_link {
      flex: 1;
      font-size: 12px;
      color: #f5f5f6;
      margin: 20px 0;
    }
    .tips_left {
      position: absolute;
      left: 28%;
      top: 28%;
      font-size: 12px;
      color: #8b8e92;
      opacity: 0.6;
    }
    .tips_right {
      position: absolute;
      right: 28%;
      top: 28%;
      font-size: 12px;
      color: #8b8e92;
      opacity: 0.6;
      text-align: right;
    }
  }
  .foot {
    height: 2%;
    background: url("./img/foot.png") no-repeat;
    background-size: 100% 100%;
  }
  .content {
    display: flex;
    height: 85%;
    width: 100%;
    .box_left,
    .box_right {
      flex: 2;
      display: flex;
      flex-direction: column;
      height: 92%;
      margin: 4% 1%;
      .chart {
        flex: 1;
        background: url("./img/chartBgds.png") no-repeat;
        background-size: 100% 100%;
        margin-bottom: 10px;
        position: relative;
        &-title {
          font-size: 14px;
          // position: absolute;
          // top:-1%;
          // left:15%;
          vertical-align: text-top;
          margin-left: 6%;
          margin-top: -7px;
          color: #cb6162;
          display: flex;
          .img_box {
            height: 12px;
            width: 12px;
            img {
              height: 80%;
              width: 100%;
            }
          }
        }
        aside {
          display: flex;
          justify-content: space-between;
          padding: 5px 20px;
        }
      }
    }

    .center_box {
      height: 100%;
      flex: 6;
      .fans {
        width: 94%;
        height: 20%;
        margin: 2% 3%;
        background: url("./img/cntbgd.png") no-repeat;
        background-size: 100% 100%;
        .data_box {
          width: 80%;
          margin: 0 10%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          li {
            // flex: 1;
            display: flex;
            flex-direction: column;
            // align-items: center;
            :first-child {
              color: #fff;
              font-size: 14px;
              padding-bottom: 5px;
            }
            :last-child {
              font-size: 36px;
              color: #17ecff;
              text-shadow: 0 2px 6px rgba(172, 250, 255, 0.76);
            }
          }
        }
      }
      .live {
        width: 100%;
        height: 74%;
        display: flex;
        .video_box {
          flex: 1;
          margin: 0 1%;
          background: url("./img/video.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &-box {
          flex: 1;
          padding: 0 10px;
          height: 100%;
          .topCar{
            background: url('./img/bgd17.png');
          }
          .comment_header{
            background: url('./img/bgd18.png');
          }
          header {
            width: 80%;
            height: 28px;
            line-height: 28px;
            color: #cb6162;
            padding-left: 10px;
            // border-bottom: 1px solid #00b9c5;
            // border-left:1px solid #00b9c5;
            display: flex;
            .img_box {
              width: 12px;
              height: 12px;
              margin-right: 5px;
            }
            img {
              height: 100%;
              width: 100%;
            }
          }
          &-content {
            margin-top: -1px;
            height: 95%;
            background: url("./img/centent.png");
            background-size: 100% 100%;
            padding-left: 10px;
            .date {
              font-size: 12px;
              color: #787980;
              text-align: center;
              padding: 10px 0;
            }
            .comment_lists {
              height: 100%;
              overflow-y: auto;
              display: block;
              @include hideSCroll();
              padding: 20px 0;
              // transition: all 1s;
              li {
                display: flex;
                font-size: 12px;
                color: #fff;
                padding-bottom: 20px;
                .comment_content {
                  flex: 1;
                  padding-left: 15px;
                  p {
                    opacity: 0.6;
                    margin-bottom: 4px;
                  }
                  .comment {
                    border: 1px solid #2b2b2e;
                    border-radius: 0 8px 8px 8px;
                    // height: 24px;
                    line-height: 16px;
                    padding: 4px;
                  }
                }
              }
            }
            .hot_car {
              // width: 100%;
              height: 100%;
              padding-bottom: 5%;
              display: block;
              li {
                height: 19%;
                padding: 4% 0;
                .content {
                  display: flex;
                  align-items: center;
                  .img_box {
                    height: 70%;
                    width: 30%;
                    margin-right: 20px;
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                  p {
                    font-size: 14px;
                    color: #ffffff;
                  }
                }
                .progress {
                  width: 95%;
                  display: flex;

                  .progress_box {
                    flex: 8;
                    height: 13px;
                    border: 1px solid rgba(111, 113, 121, 0.3);
                    border-radius: 5px;
                    display: flex;
                    .process_rate {
                      height: 10px;
                    }
                    img {
                      height: 10px;
                      margin-left: -2px;
                    }
                  }
                  .outside_tips {
                    padding-left: 4px;
                    font-size: 12px;
                    line-height: 12px;
                    color: #fff;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .city_table_rate {
    width: 90%;
    margin: 5% auto;
    height: 85%;
    tr {
      display: flex;
      width: 100%;
      height: 20%;
      th {
        opacity: 0.19;
        background: linear-gradient(
          180deg,
          rgba(0, 235, 255, 0.86) 0%,
          rgba(0, 235, 255, 0.52) 52%,
          rgba(0, 235, 255, 0.81) 100%
        );
      }
      td {
        border-top: 1px solid #252733;
      }
      th,
      td {
        height: 100%;
        flex: 1;
        font-size: 12px;
        color: #ffffff;
        opacity: 0.56;
        // padding: 2% 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>