<template>
  <div :id="id" :class="className" :style="styleObject" :chartData="chartData" :nameTip="nameTips" />
</template>
<script>
import echarts from "echarts";
import resize from "@/mirror-modules/resize";
import china from "echarts/map/json/china.json";
import common from '@/mirror-modules/common';
require("echarts/map/js/china.js");
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "bar-chart"
    },
    id: {
      type: String,
      default: "bar-chart"
    },
    boxStyle: {
      type: Object,
      default: () => {
        return { width: "874px", height: "300px" };
      }
    },
    chartData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    nameTips: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      chart: null
    };
  },

  computed: {
    styleObject: function() {
      const obj = this.boxStyle;
      for (const attr in this.boxStyle) {
        // var current = getComputedStyle(this.boxStyle)[attr]
        // 提取单位,若存在单位，得到数组。若不存在单位，得到%
        var unit = this.boxStyle[attr].match(/[a-z]+$/);
        unit = unit ? unit[0] : "%";

        obj[attr] =
          unit !== "%" ? this.boxStyle[attr] : this.boxStyle[attr] + unit;
      }
      return obj;
    }
  },
  watch: {
    chartData() {
      this.initChart();
    }
  },
  mounted() {
    // this.initChart();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(document.getElementById(this.id));

      let changeDataKey = function(data) {
        let res = [];
        for (let i = 0; i < data.length; i++) {
          res.push({
            name: data[i].province.replace("省", "").replace("市", "").replace("自治区","").replace("维吾尔","").replace("壮族",""),
            value: Number((data[i].count * 100).toFixed(2)),
            num:common.numEdit(data[i].num)
          });
        }
        return res
      };

      const data = changeDataKey(this.chartData)

      // echarts.registerMap("china", china);
      // var geoCoordMap = {};
      // /*获取地图数据*/
      // var mapName = "china";
      // // mychart.showLoading();
      // var mapFeatures = echarts.getMap(mapName).geoJson.features;
      // // mychart.hideLoading();
      // mapFeatures.forEach(function(v) {
      //   // 地区名称
      //   var name = v.properties.name;
      //   console.log('地区名称',name)
      //   // 地区经纬度
      //   geoCoordMap[name] = v.properties.cp;
      // });

      // var convertData = function(data) {
      //   console.log('补全时引入的数据',data)
      //   var res = [];
      //   for (var i = 0; i < data.length; i++) {
      //     var geoCoord = geoCoordMap[data[i].name];
      //     if (geoCoord) {
      //       res.push({
      //         name: data[i].name,
      //         value: geoCoord.concat(data[i].value)
      //       });
      //     }
      //   }
      //   console.log('返回的res',res)
      //   return res;
      // };

      this.chart.setOption({
        tooltip: {
          triggerOn: "mousemove",
          formatter: function(params) {
            if(!params.data) return;
            var a = "<span>" + params.name + "</span>";
            var b =
              '<span style="color:' +
              "rgba(23, 240, 204)" +
              '">' +
              params.value +
              "%</span>";
            var c = `<div><span>粉丝数：${params.data.num||0}</span></div>`
            return [a, b].join(" :  ")+c;
          },
          backgroundColor: "rgba(29, 38, 71)",
          // 额外附加的阴影
          extraCssText: "box-shadow:0 0 4px rgba(11, 19, 43,0.8);"
        },
        visualMap: {
          min: 0,
          max: 1000,
          left: 26,
          bottom: 40,
          showLabel: !0,
          text: ["高", "低"],
          pieces: [
            {
              gt: 100,
              color: "#77bdd4"
            },
            {
              gte: 10,
              lte: 100,
              color: "#aadbe9"
            },
            {
              gte: 1,
              lt: 10,
              color: "#cce9f2"
            },
            {
              gte: 0,
              lt: 1,
              color: "#cce9f2"
            },
            {
              value: 0,
              color: "#d2d4e0"
            }
          ],
          show: false
        },
        geo: {
          map: "china",
          roam: !1,
          scaleLimit: {
            min: 1,
            max: 2
          },
          zoom: 1,
          label: {
            show: false
          },
          emphasis: {
            label: {
              show: false
            }
          },
          itemStyle: {
            normal: {
              shadowBlur: 1,
              shadowColor: "rgba(18, 32, 70,0.4)",
              borderColor: "rgba(18, 32, 70)"
            },
            emphasis: {
              areaColor: "rgba(23, 240, 204)",
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              borderWidth: 0
            }
          }
        },
        series: [
          {
            name: "地域分布",
            type: "map",
            geoIndex: 0,
            data: data
          }
        ]
      });
    }
  }
};
</script>